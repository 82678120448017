.slideshow {
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    display: inline-block;
    min-height: 500px;
    width: 100%;
    background-size: cover;
    background-position-y: -200px
}

@media only screen and (max-width: 1000px){
    .slide{
        background-position-y: 0;
        min-height: 300px
    }
}

/* Buttons */

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;

    cursor: pointer;
    margin: 15px 7px 0;

    background-color: #c4c4c4;
}

.slideshowDot.active {
    background-color: #9CC53B;
}